<template>
  <b-card
    no-body
    class="coupon-card"
  >
    <b-card-header>
      <div
        class="d-flex flex-wrap justify-content-between align-items-center w-100 mb-2"
        style="gap: 8px;"
      >
        <h4 class="mb-0">
          {{ $t('Levels list') }}
        </h4>
        <b-button
          variant="primary"
          type="button"
          class="ml-2"
          to="add"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('Create new') }}
        </b-button>
      </div>
      <div
        class="d-flex flex-wrap justify-content-between align-items-center w-100"
        style="gap: 8px;"
      >
        <b-form-group
          class="mb-0"
        />

        <b-form-group class="mb-0">
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t('Search') }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
              style="width: 220px;"
            />
          </div>
        </b-form-group>
      </div>
    </b-card-header>
    <b-card-body class="p-0">
      <!-- table -->
      <vue-good-table
        ref="promotion-products"
        class="custom-good-table custom-good-table-ctrl-border"
        style-class="vgt-table"
        :columns="fields"
        :rows="filterItems"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <!-- Table: Head -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>

        <!-- Table: Rows -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'title'"
            class="d-flex flex-wrap align-items-center"
          >
            <span
              class="d-block line-clamp"
              style="max-width: 320px;"
              :title="props.row.title"
            >
              {{ props.row.title }}
            </span>
          </span>

          <!-- Column: Position -->
          <span
            v-else-if="props.column.field === 'turnover'"
          >
            {{ unitFormatOriginal(props.row.turnover) }}
          </span>

          <!-- Column: Status -->
          <span
            v-else-if="props.column.field === 'status'"
            class="d-block text-center"
          >
            <b-badge
              :variant="statusVariant(props.row.status)"
              class="d-inline-block"
              style="width: 100px;"
            >
              {{ $t(status[props.row.status]) }}
            </b-badge>
          </span>

          <!-- Column: Actions -->
          <span
            v-else-if="props.column.field === 'actions'"
            class="d-inline-flex text-center"
          >
            <b-button
              variant="flat-dark"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="Edit3Icon" />
            </b-button>
            <b-button
              variant="flat-danger"
              class="text-danger btn-icon rounded-circle"
              @click="handlerDelete(props.row.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap p-2">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  // BCardTitle,
  BCardBody,
  VBTooltip,
  BFormInput,
  BFormGroup,
  BPagination,
  // BAvatar,
  BFormSelect,
  BBadge,
  // BDropdown,
  // BDropdownItem,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import general from '@/mixins/index'

export default {
  name: 'DiscountList',
  components: {
    BButton,
    BCard,
    BCardHeader,
    // BCardTitle,
    BCardBody,
    // flatPickr,
    BFormInput,
    BFormGroup,
    BPagination,
    // BAvatar,
    BFormSelect,
    BBadge,
    VueGoodTable,
    // vSelect,
    // BDropdown,
    // BDropdownItem,
    // LanguageSelect,
    // BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [general],
  data() {
    return {
      fields: [
        {
          field: 'title', label: 'Levels name', sortable: false, thClass: 'text-left',
        },
        {
          field: 'turnover', label: 'Turnover', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'discount_percent', label: 'Chiết khấu', sortable: false, tdClass: 'text-center',
        },
        { field: 'status', label: 'Status', sortable: false },
        {
          field: 'created_at', label: 'Created At', sortable: false, tdClass: 'text-center',
        },
        {
          field: 'actions', label: 'Actions', sortable: false, tdClass: 'text-center',
        },
      ],
      items: [
        {
          id: 1,
          title: 'Đồng',
          turnover: 2000000,
          discount_percent: '0%',
          status: 'pending',
          created_at: '12/12/2022',
        },
        {
          id: 2,
          title: 'Bạc',
          turnover: 20000000,
          discount_percent: '10%',
          status: 'apply',
          created_at: '12/12/2022',
        },
        {
          id: 3,
          title: 'Vàng',
          turnover: 200000000,
          discount_percent: '40%',
          status: 'finished',
          created_at: '12/12/2022',
        },
      ],
      pageLength: 10,
      searchTerm: '',
      // languageList: null,
      // currentLang: JSON.parse(localStorage.getItem('siteID')).language,
      discountTypes: [
        {
          id: 1,
          name: '% chiết khấu',
        },
        {
          id: 2,
          name: 'Danh mục sản phẩm',
        },
        {
          id: 3,
          name: 'Sản phẩm',
        },
      ],
      status: {
        pending: 'Pending',
        apply: 'Apply',
        finished: 'Finished',
      },
      discountTypeSelected: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        pending     : 'light-warning',
        apply       : 'light-success',
        finished    : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.discountTypeSelected === null) return this.items
      return this.items.filter(item => item.id === this.discountTypeSelected)
    },
  },
}
</script>

<style lang="scss">
.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
.custom-good-table-ctrl-border{
  .vgt-table{
    border: 1px solid #fff !important;
  }
  td{
    padding: 0.75rem !important;

    &:first-child {
      padding-left: 1.5rem !important;
    }
    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
  thead{
    background-color: #f3f2f7
  }
}
.v-select-custom {
  .vs {
    &__selected-options {
      max-width: calc(100% - 24px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-wrap: nowrap;
    }
    &__search {
      &::placeholder {
        color: var(--gray)
      }
    }
  }
}
</style>
